import React from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import { Messenger } from "../components/Messenger"

const Section = styled.section`
  margin: 1rem;
  .page-title {
    margin: 1rem 0;
  }
  a {
    text-decoration: none;
    color: #efc11a;
  }
  p {
    margin: 1rem 0;
  }
  .email {
    list-style: none;
  }
  @media screen and (min-width: 600px) {
    .container {
      max-width: 40rem;
      margin: 0 auto;
    }
  }
`

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `About`

  const contact = data.strapiAbout.contact
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <Section>
        <div className="container">
          <h1 className="page-title">About Page</h1>
          <Markdown
            className="content"
            source={data.strapiAbout.content}
            escapeHtml={false}
          />
          <div>
            <h3>{contact.name}</h3>
            <h4>{contact.title}</h4>
            <p>{contact.company}</p>
            <p>{contact.number}</p>
            <ul>
              {contact.email.map(mail => (
                <li className="email" key={mail.id}>
                  {mail.email}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Section>
      <Messenger />
    </Layout>
  )
}

export default AboutPage
export const pageQuery = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }
    strapiAbout {
      content
      contact {
        name
        title
        company
        number
        email {
          email
          id
        }
      }
    }
  }
`
